import { useWindowMessage } from '#imports';
import { SETUP } from '@fanatics/resn-api/white-elephant';
import { EventEmitter } from 'eventemitter3';
import {
    computed,
    defineComponent,
    inject,
    nextTick,
    onMounted,
    provide,
    reactive,
    ref,
    shallowRef,
    watch,
} from 'vue';

import { useParentSearchParams } from '~/components/useParentSearchParams';
import { PALETTE_THEME } from '~/core/constants';

const FanaticsKey = 'env';
export const useFanatics = () => {
    const api = inject(FanaticsKey);
    if (!api) {
        throw new Error('useFanatics must be used within a FanaticsProvider');
    }
    return api;
};

const FanaticsProvider = defineComponent({
    setup() {
        const events = new EventEmitter();
        const { sendMessage } = useWindowMessage();

        const gameData = shallowRef({
            // const gameData = ref({
            packTextures: {},
            // Making all fields required all the time. Using empty array and -1 for default
            // values when no useful information is available forr the fields below.
            players: [],
            items: [false, false, false, false, false],

            maxPlayers: -1,

            currentViewer: '',
            currentPlayer: -1,
            currentTurn: -1,

            focusedCardIdx: -1,
        });

        const ready = ref(false);
        const rerip = ref(false); // needed in card
        const lightsDown = ref(false);

        const sceneOffset = ref(40);
        const sceneGridMargins = reactive({ top: 0, bottom: 0 });

        const colorPaletteIndex = ref(0);
        const colorPalette = computed(() => PALETTE_THEME[colorPaletteIndex.value]);

        const currentPlayer = computed(
            () => gameData.value.players[gameData.value.currentPlayer] || null
        );
        const isViewerPlayer = ref(false);

        const boundsAdjust = reactive({ width: 0, height: 80 });

        useParentSearchParams((params) => {
            if (params.get('sceneOffset')) {
                const val = parseFloat(params.get('sceneOffset'));
                if (typeof val === 'number') sceneOffset.value = val;
            }
            /* eq.use (in px): index.html?sceneGridMargins={"top":10,"bottom":20}
            default values if not provided:
                - top: viewport.height * 0.2
                - bottom: viewport.height * 0.2 */
            if (params.get('sceneGridMargins')) {
                const val = JSON.parse(params.get('sceneGridMargins'));
                if (val) {
                    sceneGridMargins.top = val.top;
                    sceneGridMargins.bottom = val.bottom;
                }
            }
        });

        onMounted(() => {});

        const sendItemSelection = (data) => {
            events.emit('ITEM_SELECTION', data);
            sendMessage('ITEM_SELECTION', data);
        };

        const setupGame = (data) => {
            updateGameData(data);
            nextTick(() => {
                events.emit(SETUP);
                ready.value = true;
            });
        };

        const updateGameData = (data) => {
            if (!data) return;
            gameData.value = data;
        };

        const setColorPalette = (val) => {
            if (val == undefined || typeof val !== 'number') return;
            colorPaletteIndex.value = val;
        };

        watch(
            () => gameData.value.message,
            (type) => {
                events.emit(type);
            }
        );

        watch(
            currentPlayer,
            (val) => (isViewerPlayer.value = gameData.value.currentViewer === val?.userName)
        );

        const FanaticsProvider = {
            // ― methods
            setupGame,
            updateGameData,

            sendItemSelection,

            setColorPalette,

            // ― data
            gameData,

            ready,
            rerip,

            currentPlayer,
            isViewerPlayer,

            colorPaletteIndex,
            colorPalette,

            lightsDown,
            events,

            sceneOffset,
            sceneGridMargins,

            boundsAdjust,
        };

        provide(FanaticsKey, FanaticsProvider);

        return FanaticsProvider;
    },

    render() {
        if (this.$slots.default) {
            return this.$slots.default();
        }

        return null;
    },
});

export default FanaticsProvider;
